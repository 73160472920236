import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { PageLayout, PageTitleSimple } from "components/PageLayout";
import { useAppRoutes } from "routes/useRoutes";
import { getQueryStringValue } from "@sprint1/pkg/src/url/getQueryStringValue";
import { Formik, Form } from "formik";

import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { Fieldset } from "@sprint1/pkg/src/form/fieldset";
import { Legend } from "@sprint1/pkg/src/form/legend/Legend";
import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { Footer } from "components/Footer";
import { MdOrthoTenant } from "api/types/mdOrthoTenant";
import { searchTenants } from "api/client/tenant/searchTenants";
import { createTenant } from "api/client/tenant/createTenant";
import { updateTenant } from "api/client/tenant/updateTenant";
import { useEffect, useState } from "react";
import { Loading } from "@sprint1/pkg/src/loading";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { LinkButton } from "components/LinkButton";
import { CtaButton } from "components/CtaButton";
import { SelectInputFormikField } from "@sprint1/pkg/src/form/select/FormikField";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { tenantFilters } from "api/helpers/tenantFilters";
import { BillingMethod } from "api/types/billingMethod";
import { MdOrthoTenantData } from "api/types/mdOrthoTenantData";
const keyof = nameof<MdOrthoTenant>;
const keyofData = nameof<MdOrthoTenantData>;

export function EditClient() {
  const { translate } = useTranslation();
  const { client, newClient, save, clients, saving } = useData();
  const { goBack } = useAppRoutes();

  const title = newClient ? translate("__addClient") : translate("__editClient");

  const loaded = client && clients;
  return (
    <PageLayout>
      <PageTitleSimple title={title} left={title} />
      {!loaded ? (
        <Loading />
      ) : (
        <Formik
          initialValues={client}
          onSubmit={(clientInForm) => {
            return save(clientInForm);
          }}
        >
          <Form>
            <div className="row">
              <div className="col-md-8 col-lg-6">
                <Field name={keyof("name")} isRequired>
                  <Label>{translate("__name")}</Label>
                  <S1InputFormikField type="text" autoFocus />
                </Field>

                {!newClient && (
                  <Fieldset>
                    <Legend isRequired>{translate("__active?")}</Legend>
                    <Field name={keyof("active")} isCheckOrRadio isInline>
                      <S1InputFormikField type="radio" value={true} parse="asBoolean" />
                      <Label isCheckOrRadio>{translate("__yes")}</Label>
                    </Field>

                    <Field name={keyof("active")} isCheckOrRadio isInline>
                      <S1InputFormikField type="radio" value={false} parse="asBoolean" />
                      <Label isCheckOrRadio>{translate("__no")}</Label>
                    </Field>
                  </Fieldset>
                )}

                <Fieldset>
                  <Legend isRequired>{translate("__billingMethod")}</Legend>
                  <Field name={`${keyof("data")}.${keyofData("billingMethod")}`} isCheckOrRadio isInline>
                    <S1InputFormikField type="radio" value={BillingMethod.TenantPay} parse="asNumber" />
                    <Label isCheckOrRadio>{translate("__tenantPay")}</Label>
                  </Field>

                  <Field name={`${keyof("data")}.${keyofData("billingMethod")}`} isCheckOrRadio isInline>
                    <S1InputFormikField type="radio" value={BillingMethod.PatientPay} parse="asNumber" />
                    <Label isCheckOrRadio>{translate("__patientPay")}</Label>
                  </Field>
                </Fieldset>

                {/* If there are no clients to select as parent, don't show it.*/}
                {clients.length > 0 && (
                  <Field name={keyof("parentId")} isRequired>
                    <Label>{translate("__parent")}</Label>
                    <SelectInputFormikField
                      options={[
                        { label: "", value: undefined },
                        ...clients.map((c) => {
                          return { label: c.name, value: c.id };
                        }),
                      ]}
                    />
                  </Field>
                )}

                <Field name={`${keyof("data")}.${keyofData("haveGenMed")}`} isCheckOrRadio>
                  <S1InputFormikField type="checkbox" formikFieldType="boolean" />
                  <Label isCheckOrRadio>{translate("__isGenMedSupported")}</Label>
                </Field>

                <Field name={keyof("isTest")} isCheckOrRadio>
                  <S1InputFormikField type="checkbox" formikFieldType="boolean" />
                  <Label isCheckOrRadio>{translate("__isTestClient")}</Label>
                </Field>

                <Footer
                  variant="placeholder"
                  left={
                    <LinkButton
                      variant="back"
                      onClick={(e) => {
                        goBack();
                      }}
                    />
                  }
                  right={<CtaButton type="submit" showSpinner={saving} />}
                />
              </div>
            </div>
          </Form>
        </Formik>
      )}
    </PageLayout>
  );
}

function useData() {
  const clientId = getQueryStringValue("clientId");
  const newClient = !clientId;
  const { go, adminRoutes } = useAppRoutes();

  const [client, setClient] = useState<MdOrthoTenant | null>(null);
  const [clients, setClients] = useState<MdOrthoTenant[] | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const toast = useToast();

  useEffect(() => {
    async function load() {
      const clientsPromise = getActiveClients(clientId);
      if (clientId) {
        const { data: clients } = await searchTenants({
          request: tenantFilters.searchTenant(clientId),
        });
        if (clients?.results?.length) {
          setClient(clients.results[0]);
        }
      } else {
        setClient({
          isTest: false,
          id: "00000000-0000-0000-0000-000000000000",
          name: "",
          active: true,
          numberOfUsers: 0,
          data: { billingMethod: BillingMethod.TenantPay, haveGenMed: false },
        });
      }
      const clients = await clientsPromise;
      setClients(clients);
    }

    load();
  }, [clientId]);

  async function getActiveClients(clientId?: string): Promise<MdOrthoTenant[]> {
    const clientsPromise = searchTenants({
      request: tenantFilters.activeClients(),
    });

    const { data } = await clientsPromise;
    if (clientId) {
      //Exclude current client
      const filtered = data.results.filter((c) => c.id !== clientId);
      return filtered;
    }

    return data.results;
  }

  async function save(updatedClient: MdOrthoTenant) {
    try {
      toast.clear();
      setSaving(true);
      if (newClient) {
        await createTenant({
          tenant: updatedClient,
        });
      } else {
        await updateTenant({
          tenant: updatedClient,
        });
      }
      go(adminRoutes.clients.list);
    } catch (e) {
      toast.error({ error: e });
    } finally {
      setSaving(false);
    }
  }

  return { client, clients, newClient, save, saving };
}
