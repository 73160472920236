import { ReactNode } from "react";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Loading } from "@sprint1/pkg/src/loading";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { MeetingInfo } from "routes/cg/common/MeetingInfo";
import { UseDataReturnType } from "../../useData";
import { EstimatedSavingsSection } from "./EstimatedSavingsSection";
import { PrescriptionSection } from "./PrescriptionSection";
import { IntakeForm } from "../IntakeForm";
import { PhysicalExamImages } from "../PhysicalExam";
import { RestrictionSection } from "./RestrictionSection";
import { Recordings } from "../../Recordings";
import { DiagnosisSection } from "./DiagnosisSection";
import { PhysicalTherapySection } from "./PhysicalTherapySection";
import { ReferralInstructionSection } from "./ReferralInstructionSection";
import { SurgeryInstructionSection } from "./SurgeryInstructionSection";
import { FollowupSection } from "./FollowupSection";
import { MiscSection } from "./MiscSection";

export function EncounterPreview({
  patientEncounter,
  injury,
  bodyParts,
  questionnaireResponse,
  encounterViewModel,
  diagnoses,
}: {
  injury: UseDataReturnType["injury"];
  bodyParts: UseDataReturnType["bodyParts"];
  questionnaireResponse: UseDataReturnType["injuryQuestionnaire"];
  patientEncounter: MdOrthoPatientEncounter;
  encounterViewModel: UseDataReturnType["encounterViewModel"];
  diagnoses: UseDataReturnType["diagnoses"];
}) {
  const { translate } = useTranslation();
  return (
    <div className="row g-3">
      <div className="col-md-3">
        <MeetingInfo patientEncounter={patientEncounter} readOnly />
        <Section title={translate("__intakeForm")}>
          {bodyParts && injury && questionnaireResponse && (
            <IntakeForm bodyParts={bodyParts} injury={injury} questionnaireResponse={questionnaireResponse} compact />
          )}
        </Section>
      </div>
      <div className="col-md">
        <Section title={translate("__physicalExam")}>
          {questionnaireResponse && <PhysicalExamImages photos={questionnaireResponse.patientResponses.photos} />}

          <div>
            <div className="d-flex ">
              <span className="me-1">
                <span className="fw-bold">{translate("__BP")}: </span>
                {patientEncounter.data.physicalExam.bloodPressure}
              </span>

              <span className="me-1">
                <span className="fw-bold">{translate("__HR")}: </span>
                {patientEncounter.data.physicalExam.heartRate}
              </span>

              <span className="me-1">
                <span className="fw-bold">{translate("__RR")}: </span>
                {patientEncounter.data.physicalExam.respiratoryRate}
              </span>

              <span className="me-1">
                <span className="fw-bold">{translate("__height")}: </span>
                {patientEncounter.data.physicalExam.height}
              </span>

              <span className="me-1">
                <span className="fw-bold">{translate("__weight")}: </span>
                {patientEncounter.data.physicalExam.weight}
              </span>
            </div>
            <div>
              <div className="fw-bold">{translate("__examNotes")}:</div>
              <pre>{patientEncounter.data.physicalExam.notes}</pre>
            </div>
          </div>
        </Section>
        <DiagnosisSection
          diagnoses={diagnoses}
          encounterViewModel={encounterViewModel}
          patientEncounter={patientEncounter}
        />

        <PhysicalTherapySection encounterViewModel={encounterViewModel} patientEncounter={patientEncounter} />

        <FollowupSection patientEncounter={patientEncounter} />
        <RestrictionSection patientEncounter={patientEncounter} encounterViewModel={encounterViewModel} />
      </div>
      <div className="col-md">
        <PrescriptionSection patientEncounter={patientEncounter} encounterViewModel={encounterViewModel} />

        <ReferralInstructionSection patientEncounter={patientEncounter} />
        <SurgeryInstructionSection patientEncounter={patientEncounter} />

        <Section title={translate("__estimatedSavings")}>
          <EstimatedSavingsSection patientEncounter={patientEncounter} />
        </Section>

        <MiscSection patientEncounter={patientEncounter} />
        <Section title={translate("__recordings")}>
          <Recordings recordings={patientEncounter?.videoAppointmentData?.recordings} />
        </Section>
      </div>
    </div>
  );
}

export function Section({ title, children }: { title: ReactNode; children?: ReactNode }) {
  return (
    <>
      <div className="mt-3 fs-5 text-uppercase">{title}</div>
      <div className="border mt-1 mb-2" />
      {children ? children : <Loading />}
    </>
  );
}
