import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { formatter } from "routes/cg/common/currencyFormatter";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { EnumHelper } from "@sprint1/pkg/src/utils/EnumHelper";
import { VisitType } from "api/types/visitType";

export function EstimatedSavingsSection({ patientEncounter }: { patientEncounter: MdOrthoPatientEncounter }) {
  const { translate } = useTranslation();
  return (
    <div className="">
      {patientEncounter.data.savings.estimatedSavings.map((e, index) => {
        return (
          <span className="me-3" key={`imaging-${e.name}-${index}`}>
            {e.name} ({formatter.format(e.cost)})
          </span>
        );
      })}
      {patientEncounter.data.savings.workDaysSaved && (
        <span className="me-3">
          {translate("__savedDAFW")}: {patientEncounter.data.savings.workDaysSaved}
        </span>
      )}
      {patientEncounter.data.savings.daysSavedFromClosingWcCaseEarly && (
        <span className="me-3">
          {translate("__daysSavedFromClosingWcCaseEarly")}:{" "}
          {patientEncounter.data.savings.daysSavedFromClosingWcCaseEarly}
        </span>
      )}
      {patientEncounter.data.savings.daysSavedFromLightDuty && (
        <span className="me-3">
          {translate("__daysSavedFromLightDuty")}: {patientEncounter.data.savings.daysSavedFromLightDuty}
        </span>
      )}

      {isDefined(patientEncounter.data.visitType) && (
        <span className="me-3">
          {translate("__avoidedOshaRecordableInjury")}:{" "}
          <span className="ms-2">
            {patientEncounter.data.savings.avoidedOshaRecordable ? translate("__yes") : translate("__no")}
          </span>
        </span>
      )}

      {isDefined(patientEncounter.data.visitType) && (
        <div className="me-3">
          {translate("__injuryType")}:{" "}
          {EnumHelper.toTranslatedString({
            value: patientEncounter.data.visitType,
            enumObj: VisitType,
            translate: translate,
            translationPrefix: "__visitType__",
          })}
        </div>
      )}
    </div>
  );
}
