import { nameof } from "@sprint1/pkg/src/ts-utils/nameof";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { isNullOrUndefined } from "@sprint1/pkg/src/utils/isNullOrUndefined";
import { Diagnosis } from "api/types/diagnosis";
import { Equipment } from "api/types/equipment";
import { EstimatedSaving } from "api/types/estimatedSaving";
import { Followup } from "api/types/followup";
import { Order } from "api/types/order";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { PhysicalExam } from "api/types/physicalExam";
import { PhysicalTherapy } from "api/types/physicalTherapy";
import { Prescription } from "api/types/prescription";
import { Referral } from "api/types/referral";
import { Restrictions } from "api/types/restrictions";
import { Savings } from "api/types/savings";
import { Surgery } from "api/types/surgery";

export const name = {
  patientEncounterData: nameof<PatientEncounterData>,
  savings: nameof<Savings>,
  estimatedSavings: nameof<EstimatedSaving>,

  equipments(key: keyof Equipment, index?: number) {
    const indexChecked = isNullOrUndefined(index);
    if (indexChecked.hasValue && indexChecked.value >= 0) {
      return `${name.patientEncounterData("equipments")}[${index}].${key}`;
    } else {
      return key;
    }
  },
  prescription(key: keyof Prescription, index?: number) {
    const indexChecked = isNullOrUndefined(index);
    if (indexChecked.hasValue && indexChecked.value >= 0) {
      return `${name.patientEncounterData("prescriptions")}[${index}].${key}`;
    } else {
      return key;
    }
  },
  physicalTherapy(key: keyof PhysicalTherapy, index?: number) {
    if (isDefined(index)) {
      return `${name.patientEncounterData("physicalTherapies")}[${index}].${key}`;
    } else {
      return key;
    }
  },
  orders(key: keyof Order, index?: number) {
    const indexChecked = isNullOrUndefined(index);
    if (indexChecked.hasValue && indexChecked.value >= 0) {
      return `${name.patientEncounterData("orders")}[${index}].${key}`;
    } else {
      return key;
    }
  },
  diagnoses(key: keyof Diagnosis, prefixDiagnoses: boolean) {
    if (prefixDiagnoses) {
      return `${name.patientEncounterData("diagnoses")}.${key}`;
    } else {
      return key;
    }
  },
  physicalExam(key: keyof PhysicalExam, prefixPhysicalExam: boolean) {
    if (prefixPhysicalExam) {
      return `${name.patientEncounterData("physicalExam")}.${key}`;
    } else {
      return key;
    }
  },
  surgery(key: keyof Surgery, prefixSurgery: boolean) {
    if (prefixSurgery) {
      return `${name.patientEncounterData("surgery")}.${key}`;
    } else {
      return key;
    }
  },
  referral(key: keyof Referral, prefixReferral: boolean) {
    if (prefixReferral) {
      return `${name.patientEncounterData("referral")}.${key}`;
    } else {
      return key;
    }
  },
  restrictions(key: keyof Restrictions, prefixRestrictions: boolean) {
    if (prefixRestrictions) {
      return `${name.patientEncounterData("restrictions")}.${key}`;
    } else {
      return key;
    }
  },
  followup(key: keyof Followup, prefixFollowup: boolean) {
    if (prefixFollowup) {
      return `${name.patientEncounterData("followup")}.${key}`;
    } else {
      return key;
    }
  },
};
