import { HeaderLinkType } from "@sprint1/pkg/src/header";
import { TranslateFnType, useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { User } from "@sprint1/pkg/src/api/auth/types/user";
import { RolesEnum } from "common/roles";
import { routes } from "routes/routes.config";
import { useUser } from "@sprint1/pkg/src/auth/user/context";

import { UseAppUserReturnType, useAppUser } from "common/useAppUser";

export function getMainLinks(
  translate: TranslateFnType,
  hasRole: (role: string) => boolean,
  appUser: UseAppUserReturnType,
  user?: User
): HeaderLinkType[] {
  const mainLinks: HeaderLinkType[] = [];

  if (hasRole(RolesEnum.OnsiteNurse) || hasRole(RolesEnum.Patient)) {
    mainLinks.push({
      label: translate("__home"),
      route: routes.patient.dashboard,
    });

    mainLinks.push({
      label: translate("__messages"),
      route: routes.messages.pathname,
    });
  }

  if (appUser.isDoctor || appUser.isMdOrthoNurse) {
    mainLinks.push({
      label: translate("__schedule"),
      route: routes.careGivers.schedule,
    });
  }

  if (appUser.isDoctor || appUser.isMdOrthoNurse) {
    mainLinks.push({
      label: translate("__patients"),
      route: routes.careGivers.patients,
    });
  }

  if (appUser.isDoctor || appUser.isMdOrthoNurse) {
    mainLinks.push({
      label: translate("__messages"),
      route: routes.messages.pathname,
    });
  }

  if (appUser.isDoctor || appUser.isMdOrthoNurse) {
    mainLinks.push({
      label: translate("__results"),
      route: routes.careGivers.results,
    });
  }

  if (appUser.isDoctor || appUser.isMdOrthoNurse || appUser.isOnSiteNurse) {
    mainLinks.push({
      label: translate("__workersComp"),
      route: routes.careGivers.workersComp,
    });
  }
  return mainLinks;
}

export function useAdditionalLinks() {
  const { user } = useUser();
  const appUser = useAppUser();
  const { translate } = useTranslation();
  const additionalLinks: HeaderLinkType[] = [];

  if (user && user.tenantId && appUser.isDoctor) {
    additionalLinks.push({
      label: translate("__profile"),
      route: routes.admin.users.edit.url({ userId: user.id, clientId: user.tenantId }),
    });
  }

  if (appUser.isDoctor || appUser.isMdOrthoNurse) {
    additionalLinks.push({
      label: translate("__scheduleAppointment"),
      route: routes.patient.selectBodyPart.url().fullUrl,
    });
  }

  if (appUser.isAdmin) {
    additionalLinks.push({
      label: translate("__clients"),
      route: routes.admin.clients.list,
    });

    additionalLinks.push({
      label: translate("__referralPartners"),
      route: routes.admin.referralPartners.list,
    });
  }

  if (appUser.isClientAdmin && appUser.user?.tenantId) {
    additionalLinks.push({
      label: translate("__manageUsers"),
      route: routes.admin.users.list.get(appUser.user.tenantId, "").fullUrl,
    });
  }

  if (appUser.isDoctor) {
    additionalLinks.push({
      label: translate("__settings"),
      route: routes.admin.settings.path,
    });
  }

  if (user) {
    additionalLinks.push({
      label: translate("__physicalTherapyVideos"),
      route: routes.library.physicalTherapy,
      divider: additionalLinks.length ? "above" : undefined,
    });
    additionalLinks.push({
      label: translate("__orthoLibrary"),
      route: routes.library.ortho,
    });
  }

  additionalLinks.push({
    label: translate("__privacyPolicy"),
    route: commonLinks.privacyPolicy,
    useAnchorTag: true,
    divider: additionalLinks.length ? "above" : undefined,
  });

  additionalLinks.push({
    label: translate("__termsOfUse"),
    route: commonLinks.termsOfUse,
    useAnchorTag: true,
  });

  return { additionalLinks };
}

export const commonLinks = {
  privacyPolicy: "/public/docs/PrivacyPolicy.pdf",
  termsOfUse: "/public/docs/TermsOfUse.pdf",
};
