import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

import { useField } from "formik";
import { PatientEncounterData } from "api/types/patientEncounterData";

import { UseDataReturnType } from "../useData";
import { name } from "../name";

import { Fieldset } from "@sprint1/pkg/src/form/fieldset";
import { Legend } from "@sprint1/pkg/src/form/legend/Legend";
import { EstimatedSaving } from "api/types/estimatedSaving";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { formatter } from "routes/cg/common/currencyFormatter";
import { S1Input } from "@sprint1/pkg/src/form/input";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { VisitType } from "api/types/visitType";

export function EstimatedSavings({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [field, , helpers] = useField<PatientEncounterData["savings"]["estimatedSavings"]>({
    name: `${name.patientEncounterData("savings")}.${name.savings("estimatedSavings")}`,
  });

  function handleEstimatedSavingsChange(estimatedSaving: EstimatedSaving, action: "upsert" | "delete") {
    if (action === "upsert") {
      const allExceptTheCurrent = field.value.filter((es) => !(es.name === estimatedSaving.name));
      const newValue = [...allExceptTheCurrent, estimatedSaving];
      helpers.setValue(newValue);
      helpers.setTouched(true);
    } else {
      const newValue = field.value.filter((es) => !(es.name === estimatedSaving.name));
      helpers.setValue(newValue);
      helpers.setTouched(true);
    }
  }

  const estimatedSavingsOptions = data.encounterViewModel?.estimatedSavings;
  return (
    <div>
      <Fieldset defaultClassName="">
        <Legend>{translate("__standardChargesSaved")}</Legend>
        <div className="row">
          {estimatedSavingsOptions &&
            estimatedSavingsOptions.map((e, index) => {
              const matchingEstimatedSavings = field.value.find((es) => es.name === e.name);
              return (
                <div className="col-12" key={`estimatedSaving-${index}-${e.name}`}>
                  <Field name={`estimatedSavings-${index}`} isCheckOrRadio isInline>
                    <S1InputField
                      type="checkbox"
                      checked={isChecked(field.value, e)}
                      onChange={(event) => {
                        handleEstimatedSavingsChange(e, event.target.checked ? "upsert" : "delete");
                      }}
                    />
                    <Label>
                      {e.name} {!matchingEstimatedSavings && <>({formatter.format(e.cost)})</>}
                    </Label>
                    {matchingEstimatedSavings && (
                      <S1Input
                        type="number"
                        value={matchingEstimatedSavings.cost}
                        step="25"
                        min="0"
                        onChange={(event) => {
                          handleEstimatedSavingsChange({ ...e, cost: event.target.valueAsNumber }, "upsert");
                        }}
                        style={{ maxWidth: "3.5rem" }}
                        className="d-inline form-control form-control-sm ms-2"
                      />
                    )}
                  </Field>
                </div>
              );
            })}
        </div>
        <div className="row">
          <div className="col-12">
            <Field name={`${name.patientEncounterData("savings")}.${name.savings("workDaysSaved")}`}>
              <Label>{translate("__savedDAFW")}</Label>
              <S1InputFormikField type="number" min={0} style={{ width: "4rem" }} />
            </Field>
          </div>
          <div className="col-12">
            <Field name={`${name.patientEncounterData("savings")}.${name.savings("daysSavedFromClosingWcCaseEarly")}`}>
              <Label>{translate("__daysSavedFromClosingWcCaseEarly")}</Label>
              <S1InputFormikField type="number" min={0} style={{ width: "4rem" }} />
            </Field>
          </div>
          <div className="col-12">
            <Field name={`${name.patientEncounterData("savings")}.${name.savings("daysSavedFromLightDuty")}`}>
              <Label>{translate("__daysSavedFromLightDuty")}</Label>
              <S1InputFormikField type="number" min={0} style={{ width: "4rem" }} />
            </Field>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Fieldset defaultClassName="">
              <Legend>{translate("__injuryType")}</Legend>
              <Field name={`${name.patientEncounterData("visitType")}`} isCheckOrRadio>
                <Label>{translate("__visitType__Simple")}</Label>
                <S1InputFormikField type="radio" parse="asNumber" value={VisitType.Simple} />
              </Field>
              <Field name={`${name.patientEncounterData("visitType")}`} isCheckOrRadio>
                <Label>{translate("__visitType__Complex")}</Label>
                <S1InputFormikField type="radio" parse="asNumber" value={VisitType.Complex} />
              </Field>
            </Fieldset>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <Field
              name={`${name.patientEncounterData("savings")}.${name.savings("avoidedOshaRecordable")}`}
              className="mt-2"
              isCheckOrRadio
            >
              <Label>{translate("__avoidedOshaRecordableInjury")}</Label>
              <S1InputFormikField type="checkbox" formikFieldType="boolean" />
            </Field>
          </div>
        </div>
      </Fieldset>
    </div>
  );
}

function isChecked(estimatedSavings: EstimatedSaving[], estimatedSaving: EstimatedSaving) {
  const matching = estimatedSavings.find((e) => e.cost && e.name === estimatedSaving.name);
  return matching !== undefined;
}
