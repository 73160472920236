import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

import { UseDataReturnType } from "../useData";
import { name } from "../name";

import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";

export function Misc({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();

  return (
    <div className="row">
      <div className="col-12">
        <Field name={`${name.patientEncounterData("isGenMedVisit")}`} className="mt-2" isCheckOrRadio>
          <Label>{translate("__isGenMed")}</Label>
          <S1InputFormikField type="checkbox" formikFieldType="boolean" />
        </Field>
      </div>
      <div className="col-12">
        <Field name={`${name.patientEncounterData("isInPersonVisit")}`} className="mt-2" isCheckOrRadio>
          <Label>{translate("__isInPersonVisit")}</Label>
          <S1InputFormikField type="checkbox" formikFieldType="boolean" />
        </Field>
      </div>
    </div>
  );
}
