import { Field } from "@sprint1/pkg/src/form/field";

import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { Fieldset } from "@sprint1/pkg/src/form/fieldset";
import { Legend } from "@sprint1/pkg/src/form/legend/Legend";
import { PageLayout } from "../components/PageLayout";
import { Section } from "../components/Section";
import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";

import { Label } from "@sprint1/pkg/src/form/label";
import { SelectInputFormikField } from "@sprint1/pkg/src/form/select/FormikField";
import { name } from "./name";
import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";

export function ProfileSection() {
  const { translate } = useTranslation();
  return (
    <PageLayout titleKey="__medicalHistory" displayGreeting>
      <Section titleKey="__profile">
        <Field name={name.medicalHistoryData("gender")} isRequired>
          <Label>{translate("__gender")}</Label>
          <SelectInputFormikField
            options={[
              { label: "", value: "" },
              { label: translate("__male"), value: "male" },
              { label: translate("__female"), value: "female" },
            ]}
          ></SelectInputFormikField>
        </Field>

        <Fieldset>
          <Legend isRequired>{translate("__doYouSmoke")}</Legend>
          <Field name={name.medicalHistoryData("smoke")} isCheckOrRadio isInline>
            <S1InputFormikField type="radio" value={true} parse="asBoolean" />
            <Label isCheckOrRadio>{translate("__yes")}</Label>
          </Field>

          <Field name={name.medicalHistoryData("smoke")} isCheckOrRadio isInline>
            <S1InputFormikField type="radio" value={false} parse="asBoolean" />
            <Label isCheckOrRadio>{translate("__no")}</Label>
          </Field>
        </Fieldset>

        <Fieldset>
          <Legend isRequired>{translate("__doYouDrink")}</Legend>
          <Field name={name.medicalHistoryData("alcohol")} isCheckOrRadio isInline>
            <S1InputFormikField type="radio" value={true} parse="asBoolean" />
            <Label isCheckOrRadio>{translate("__yes")}</Label>
          </Field>

          <Field name={name.medicalHistoryData("alcohol")} isCheckOrRadio isInline>
            <S1InputFormikField type="radio" value={false} parse="asBoolean" />
            <Label isCheckOrRadio>{translate("__no")}</Label>
          </Field>
        </Fieldset>

        <Field name={name.pharmacy("name")} isRequired>
          <Label>{translate("__pharmacyName")}</Label>
          <S1InputFormikField />
        </Field>

        <Field name={name.pharmacy("phoneNumber")} isRequired>
          <Label>{translate("__pharmacyPhoneNumber")}</Label>
          <S1InputFormikField type="tel" />
        </Field>

        <Field name={name.pharmacy("address")}>
          <Label>{translate("__pharmacyAddress")}</Label>
          <TextAreaFormikField />
        </Field>
      </Section>
    </PageLayout>
  );
}
