import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { Section } from ".";

export function MiscSection({ patientEncounter }: { patientEncounter: MdOrthoPatientEncounter }) {
  const { translate } = useTranslation();

  const followup = patientEncounter.data.followup;
  const nonEmptyFollowup = isDefined(followup.notes) || isDefined(followup.followupDate);

  if (!nonEmptyFollowup) {
    return (
      <Section title={translate("__misc")}>
        <div>{translate("__none")}</div>
      </Section>
    );
  }
  return (
    <Section title={translate("__misc")}>
      <div className=" mt-3">
        <div>
          <span className="">{translate("__isGenMed")}</span>
          <span className="ms-2">{patientEncounter.data.isGenMedVisit ? translate("__yes") : translate("__no")}</span>
        </div>
        <div>
          <span className="">{translate("__isInPersonVisit")}</span>
          <span className="ms-2">{patientEncounter.data.isInPersonVisit ? translate("__yes") : translate("__no")}</span>
        </div>
      </div>
    </Section>
  );
}
