import { PostHogConfig } from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { usePostHog } from "posthog-js/react";

import { useEffect } from "react";

import { useAppUser } from "./useAppUser";

const testEnvPhKey = "phc_MYsQwaGYv4IESmVfx9OaSkatzl1BNHopGDXYncxswor";
const prodEnvPhKey = "phc_3VwuoxD1IrQHYA1Nx7fRd2YLdKNf76YxSxcoAzWpszi";
// window.location.hostname.startsWith("appLocal")   ? ""
const apiKey = window.location.hostname.toLowerCase().startsWith("applocal")
  ? ""
  : window.location.hostname.toLowerCase().endsWith(".mdortho.ai")
  ? prodEnvPhKey
  : testEnvPhKey;
const options = {
  person_profiles: "always",
  session_recording: {
    maskAllInputs: false,
    maskInputOptions: {
      password: true,
    },
  },
} satisfies Partial<PostHogConfig>;

export function MdoPostHogProvider({ children }: { children: React.ReactNode }) {
  return (
    <PostHogProvider apiKey={apiKey} options={options}>
      {children}
    </PostHogProvider>
  );
}

export function usePostHogTracker() {
  const posthog = usePostHog();

  const appUser = useAppUser();
  const { user } = appUser;
  useEffect(() => {
    async function load() {
      if (user) {
        posthog?.identify(user.id, {
          email: user.email,
          name: `${user?.firstName} ${user?.lastName}`,
          role: appUser.currentRole,
          isAdmin: appUser.isAdmin,
          isDoctor: appUser.isDoctor,
          isMdOrthoNurse: appUser.isMdOrthoNurse,
          isWcAdmin: appUser.isOnSiteNurse,
          isPatient: appUser.isPatient,
        });
        if (user.tenantId) {
          posthog?.group("tenant", user.tenantId);
        }
      } else {
        posthog?.reset();
      }
    }
    load();
  }, [
    appUser.currentRole,
    appUser.isAdmin,
    appUser.isDoctor,
    appUser.isMdOrthoNurse,
    appUser.isOnSiteNurse,
    appUser.isPatient,
    appUser.user?.tenantId,
    posthog,
    user,
  ]);
}
