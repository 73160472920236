import { useState } from "react";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

import { useField } from "formik";

import { TextAreaFormikField } from "@sprint1/pkg/src/form/textArea/FormikField";
import { UseDataReturnType } from "../useData";
import { name } from "../name";

import { S1InputFormikField } from "@sprint1/pkg/src/form/input/FormikField";
import { PatientEncounterData } from "api/types/patientEncounterData";
import { ReSelect } from "@sprint1/pkg/src/reSelect";
import { Followup as FollowupType } from "api/types/followup";
import { s1Date } from "@sprint1/pkg/src/date";

export function Followup({ data }: { data: UseDataReturnType }) {
  const { translate } = useTranslation();
  const [numberOfWeeksToFollowup, setNumberOfWeeksToFollowup] = useState(0);
  const [field, , helpers] = useField<PatientEncounterData["followup"]>({
    name: name.patientEncounterData("followup"),
  });

  return (
    <div>
      <div className="mb-2">
        <ReSelect
          onChange={({ selectedOption }) => {
            const followup: FollowupType = { ...field.value };
            if (selectedOption?.value === 0) {
              helpers.setValue({ ...followup, followupDate: undefined });
              helpers.setTouched(true);
            } else {
              const followupDate = s1Date.addDays(selectedOption?.value * 7, new Date());
              helpers.setValue({ ...followup, followupDate });
              helpers.setTouched(true);
            }
            setNumberOfWeeksToFollowup(selectedOption?.value ?? 0);
          }}
          value={numberOfWeeksToFollowup}
          options={[
            { label: translate("__followupIn"), value: 0 },
            { label: translate("__followupInOneWeek"), value: 1 },
            { label: translate("__followupInTwoWeeks"), value: 2 },
            { label: translate("__followupInFourWeeks"), value: 4 },
            { label: translate("__followupInTwoMonths"), value: 8 },
          ]}
        />
      </div>
      <Field name={name.followup("followupDate", true)}>
        <Label>{translate("__followupIn")}</Label>
        <S1InputFormikField type="date" />
      </Field>

      <Field name={name.followup("notes", true)}>
        <Label>{translate("__notes")}</Label>
        <TextAreaFormikField autoFocus />
      </Field>
    </div>
  );
}
