import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

import { PageLayout } from "./components/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserInjured } from "@fortawesome/free-solid-svg-icons/faUserInjured";
import { Footer } from "../../components/Footer";
import { useAppRoutes } from "routes/useRoutes";
import { Field } from "@sprint1/pkg/src/form/field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { useState } from "react";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { useUser } from "@sprint1/pkg/src/auth/user/context";
import { Loading } from "@sprint1/pkg/src/loading";

export function WorkersCompDisclaimer() {
  const { translate } = useTranslation();
  const { user } = useUser();
  const routes = useAppRoutes();
  const toast = useToast();
  const [confirmed, setConfirmed] = useState(false);

  if (!user) {
    return <Loading />;
  }
  return (
    <>
      <PageLayout titleKey="__workersComp" displayGreeting>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="text-center  fs-3 text-uppercase ">
              <div>
                <FontAwesomeIcon icon={faUserInjured} size="2x" />
              </div>
              <div className="pt-2">{translate("__workersComp")}</div>
            </div>

            <p className="py-4">{translate("__notWorkersCompMessage")}</p>

            <div>
              <Field name="workersComp" isCheckOrRadio isRequired>
                <S1InputField
                  type="checkbox"
                  checked={confirmed}
                  onChange={() => setConfirmed((prevConfirmed) => !prevConfirmed)}
                />
                <Label>{translate("__confirmItIsNotWorkersComp")}</Label>
              </Field>
            </div>
          </div>
        </div>
      </PageLayout>

      <Footer
        variant="cancelAndContinue"
        onCancelClick={() => {
          routes.goToPatientRoutes("dashboard");
        }}
        onSaveClick={() => {
          if (confirmed) {
            routes.go(routes.patientRoutes.medicalHistory.getUrl({ patientId: user.id, redirectTo: "selectBodyPart" }));
          } else {
            toast.clear();
            toast.error(translate("__pleaseConfirmNotWorkersCompClaim"));
          }
        }}
      />
    </>
  );
}
