export enum AlternateCare {
  Unknown = 0,
  PrimaryCare = 1,
  EmergencyRoom = 2,
  UrgentCare = 3,
  PhysicalTherapy = 4,
  OrthoSpecialist = 7,
  Surgeon = 8,
  PainManagement = 9,
  Chiropractor = 5,
  WontSeekCare = 6,
}
