import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";

import { PageLayout } from "./components/PageLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons/faCircleInfo";
import { Footer } from "../../components/Footer";
import { useAppRoutes } from "routes/useRoutes";
import { Field } from "@sprint1/pkg/src/form/field";
import { S1InputField } from "@sprint1/pkg/src/form/input/Field";
import { Label } from "@sprint1/pkg/src/form/label";
import { useState } from "react";
import { useToast } from "@sprint1/pkg/src/toast/useToast";
import { Trans } from "react-i18next";
import { getAppConfigGlobal } from "common/getAppConfig";
import { useRunOnMount } from "@sprint1/pkg/src/useRunOnMount/useRunOnMount";
import { BillingMethod } from "api/types/billingMethod";
import { useAppUser } from "common/useAppUser";
import { ViewPortLoading } from "@sprint1/pkg/src/loading/ViewPortLoading";

export function MedicalEmergency() {
  const { translate } = useTranslation();
  const [confirmed, setConfirmed] = useState(false);
  const [acceptTou, setAcceptTou] = useState(false);
  const routes = useAppRoutes();
  const toast = useToast();
  const { user } = useAppUser();

  useRunOnMount(() => {
    async function load() {
      await getAppConfigGlobal();
    }
    load();
  });

  if (!user) {
    return <ViewPortLoading />;
  }

  return (
    <>
      <PageLayout titleKey="__isThisMedicalEmergency" displayGreeting>
        <div className="row d-flex justify-content-center">
          <div className="col-lg-5 col-md-8">
            <div className="text-center text-danger fs-3 text-uppercase ">
              <div>
                <FontAwesomeIcon icon={faCircleInfo} size="2x" />
              </div>
              <div className="pt-3">{translate("__isThisMedicalEmergency")}</div>
            </div>

            <ul className="mt-4">
              <li>{translate("__medicalEmergencyNotice")}</li>
            </ul>

            <div
              className="my-3 p-3 text-center fw-bold"
              style={{ backgroundColor: "rgba(var(--bs-danger-rgb), 0.10)" }}
            >
              {translate("__ifAnsweredYesCall911")}
            </div>

            <div className="">
              <Field name="medicalEmergency" isCheckOrRadio isRequired>
                <S1InputField
                  type="checkbox"
                  checked={confirmed}
                  onChange={() => setConfirmed((prevConfirmed) => !prevConfirmed)}
                />
                <Label>{translate("__confirmItIsNotMedicalEmergency")}</Label>
              </Field>
              <Field name="acceptTou" isCheckOrRadio isRequired>
                <S1InputField
                  type="checkbox"
                  checked={acceptTou}
                  onChange={() => setAcceptTou((prevAcceptTou) => !prevAcceptTou)}
                />

                <Label>
                  <Trans i18nKey="__acceptTermsOfUseAndPrivacyPolicy">
                    <a target="_blank" href="/public/docs/TermsOfUse.pdf">
                      Terms of use
                    </a>
                    <a target="_blank" href="/public/docs/PrivacyPolicy.pdf">
                      Privacy Policy
                    </a>
                  </Trans>
                </Label>
              </Field>
            </div>
          </div>
        </div>
      </PageLayout>

      <Footer
        variant="cancelAndContinue"
        onSaveClick={async () => {
          if (confirmed && acceptTou) {
            const appConfig = await getAppConfigGlobal();
            if (appConfig?.tenant?.data?.billingMethod === BillingMethod.PatientPay) {
              routes.go(
                routes.patientRoutes.medicalHistory.getUrl({ patientId: user.id, redirectTo: "selectBodyPart" })
              );
            } else {
              routes.goToPatientRoutes("workersCompDisclaimer");
            }
          } else {
            toast.clear();
            toast.error(translate("__pleaseConfirmNotMedicalEmergency"));
          }
        }}
        onCancelClick={async () => {
          routes.goToPatientRoutes("dashboard");
        }}
      />
    </>
  );
}
