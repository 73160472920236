import { useTranslation } from "@sprint1/pkg/src/i18n/useTranslation";
import { isDefined } from "@sprint1/pkg/src/utils/isDefined";
import { MdOrthoPatientEncounter } from "api/types/mdOrthoPatientEncounter";
import { Section } from ".";
import { toUtcDate } from "common/toUtcDate";

export function FollowupSection({ patientEncounter }: { patientEncounter: MdOrthoPatientEncounter }) {
  const { translate } = useTranslation();

  const followup = patientEncounter.data.followup;
  const nonEmptyFollowup = isDefined(followup.notes) || isDefined(followup.followupDate);

  if (!nonEmptyFollowup) {
    return (
      <Section title={translate("__followup")}>
        <div>{translate("__none")}</div>
      </Section>
    );
  }
  return (
    <Section title={translate("__followup")}>
      <div className="ms-3 mt-3">
        <div className="ms-3">
          {patientEncounter.data.followup.notes && (
            <>
              <div className="fw-bold text-uppercase">{translate("__notes")}</div>
              <pre>{patientEncounter.data.restrictions.notes ?? translate("__none")}</pre>
            </>
          )}

          {patientEncounter.data.followup?.followupDate && (
            <>
              <div className="fw-bold text-uppercase">{translate("__followupIn")}</div>
              <div>{toUtcDate(patientEncounter.data.followup.followupDate)}</div>
            </>
          )}
        </div>
      </div>
    </Section>
  );
}
