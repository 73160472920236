import { impersonate } from "@sprint1/pkg/src/api/auth/client/auth/impersonate";
import { ImpersonateRequest } from "@sprint1/pkg/src/api/auth/types/impersonateRequest";
import { useState } from "react";

export function Impersonate() {
  const data = useData();
  return (
    <div className="container py-2">
      <div className="form-floating">
        <textarea
          className="form-control"
          placeholder="Leave a comment here"
          id="floatingTextarea"
          value={data.payload}
          onChange={(e) => {
            data.setPayload(e.target.value);
          }}
          rows={10}
          style={{ width: "100%", height: "150px" }}
        ></textarea>
        <label htmlFor="floatingTextarea">Impersonate Payload</label>
      </div>
      <button className="btn btn-primary my-2" onClick={data.handleOnClick}>
        Impersonate
      </button>
    </div>
  );
}

function useData() {
  const [payload, setPayload] = useState(() => {
    return JSON.stringify(initialRequest, null, 2);
  });
  async function handleOnClick() {
    const payloadParsed = JSON.parse(payload) as ImpersonateRequest;
    await impersonate({ request: payloadParsed });
    window.location.reload();
  }
  return { handleOnClick, payload, setPayload };
}

const initialRequest: ImpersonateRequest = {
  email: "optional@email.info",
  tenantId: "4e4ebed4-c4d1-42d8-aaa5-00000000000",
  userId: "c6212804-cbfb-4689-8b7d-00000000000",
};
